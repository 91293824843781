<template>
    <div class="plagiarism-policy section container">
    
      <div class="row pt-5">
          <div class="clearfix">
    
            <header class="header mb-4">
              <h1 class="display-2">Plagiarism Policy</h1>
            </header>
            
      <main id="main-content">
    
        <p class="lead">CSU Global expects assignment submissions to represent the original work of the individual
student.</p>
<ul>
<li>At least 70% of assignment content should be the student’s original thoughts, analysis,
and syntheses of course material. Paraphrasing, with proper citations and references, is
considered an appropriate analysis in supporting student’s thoughts.</li>
<li>As a general guideline, student assignments should return an originality check similarity
score of 30% or less, with no more than 20% of the student’s paper containing quoted
material.
<ul>
<li>Students can submit work labeled as “Draft” in the grade book comments, this
allows student’s to review their TurnItIn score without their work being graded.
Once the assignment is ready for grading students will resubmit the work labeled
as “Final”</li>
</ul>
</li>
</ul>
<p class="lead">Students are encouraged to utilize the Writing Center and Writing Coaches if there are
questions regarding citation, paraphrasing or including enough original information.</p>

<h4>What happens if there is a plagiarism concern with my work?</h4>
<p>If an instructor has a concern regarding work they will either contact the student or ask the Student Affairs department to review the concern. While concerns are reviewed a “1” will be placed in the gradebook.</p>
<p>If students have concerns or questions about the TurnItIn report or the review process they can reach out to their Student Success Counselor or the Student Affairs department at <a href="mailto:student.affairs@csuglobal.edu">student.affairs@csuglobal.edu</a>.</p>
<p><strong>Helpful Resources</strong></p>
<ul>
<li>CSU-Global Guide to Writing and APA, Introduction and Why Cite?: <a href="http://csuglobal.libguides.com/apacitations" target="_blank">http://csuglobal.libguides.com/apacitations</a></li>
<li>CSU-Global Guide to Writing and APA, In-Text Citations: <a href="http://csuglobal.libguides.com/apacitations/in-text" target="_blank">http://csuglobal.libguides.com/apacitations/in-text</a></li>
<li>CSU-Global Guide to Writing and APA, Reference List: <a href="http://csuglobal.libguides.com/apacitations/reflist" target="_blank">http://csuglobal.libguides.com/apacitations/reflist</a></li>
<li>CSU-Global Writing Center resources: <a href="https://csuglobal.libguides.com/writingcenter/apa_resources/avoiding_plagiarism#Submitting_Assignments" target="_blank">Submitting Assignments</a></li>
<li>CSU-Global Writing Center resources: <a href="https://csuglobal.libguides.com/writingcenter/apa_resources/avoiding_plagiarism#Interpreting_Originality_Reports" target="_blank">Interpreting Originality Reports</a></li>
</ul>


<h3>CSU Global AI Policy</h3>
<p>Artificial Intelligence (AI) tools can be used for a deeper understanding of concepts in all courses and provide an expansion of competencies in the classroom. Some faculty can encourage the use of AI tools in the classroom to support student innovation and creativity on assignments and help students develop a solution path. However, the unethical use of any artificial intelligence (AI) tool like Chat GPT, Claude, BingAI, Google Bard, or Chat Sonic, in the completion of submitted coursework is prohibited. An example of an unethical use includes using fully AI-generated content directly from an AI tool and claiming it as your own work in submission for a grade.</p>

<ul>
<li>Student’s are able to use tools that review their original work and provide recommendations to improve their work.</li>
<li>Students are NOT able to submit work created by an AI tool for grading.</li>
<li>Students need to ensure that their work includes in-text citations and all of their sources are listed. Sources and in-text citations need to align.</li>
</ul>


<h4>What happens if there is an AI concern with my work?</h4>
<p>Instructors receive a TurnItIn report that focuses on identifying possible AI content. If the score is high then the instructor will review the work and either contact the student directly or ask the Student Affairs department to review the concern. While concerns are reviewed a “1” will be placed in the gradebook.</p>

<p>If students have concerns or questions about the AI concern or the review process they can reach out to their Student Success Counselor or the Student Affairs department at <a href="mailto:student.affairs@csuglobal.edu">student.affairs@csuglobal.edu</a>.</p>

<p><strong>Helpful Resources</strong></p>
<ul>
<li><a href="https://csuglobal.libguides.com/ld.php?content_id=75931198" target="_blank">Do's and Don'ts of AI</a></li>
<li>CSU-Global Guide to Writing and APA, Introduction and Why Cite?: <a href="http://csuglobal.libguides.com/apacitations" target="_blank">http://csuglobal.libguides.com/apacitations</a></li>
<li>CSU-Global Guide to Writing and APA, In-Text Citations: <a href="http://csuglobal.libguides.com/apacitations/in-text" target="_blank">http://csuglobal.libguides.com/apacitations/in-text</a></li>
<li>CSU-Global Guide to Writing and APA, Reference List: <a href="http://csuglobal.libguides.com/apacitations/reflist" target="_blank">http://csuglobal.libguides.com/apacitations/reflist</a></li>
</ul> 
      </main>
    
          </div><!--/clearfix-->
      </div><!--/row-->
      </div>
      <!--End Container -->
    
    <aside>
      <AdvisorCallToAction />
    </aside>
    </template>
    
    
    <script>
    // @ is an alias to /src
    import AdvisorCallToAction from "@/components/AdvisorCallToAction.vue";
    
    export default {
      name: "AcademicDishonesty",
      components: {
        AdvisorCallToAction,
      },
       mounted() {
        document.title = 'Academic Dishonesty | CSU Global Appeals Center';
            
        },
    };
    </script>
    
    
    